.h1 {
  position: relative;
  top: 10px;
  left: 0px;
  right: 0px;
  margin-top: 0px;
  font-size: 14vw;
  text-align: center;
  line-height: 22vh;
  background: repeating-linear-gradient(45deg, var(--bright-purple), var(--bright-purple) 10px, var(--white) 10px, var(--white) 20px), var(--white);
  background-size: 100%;
  background-repeat: repeat-y;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 2;
  user-select: none;
}

.h2 {
  position: absolute;
  font-size: 20vh;
  line-height: 12vh;
  bottom: 0px;
  overflow-wrap: break-word;
  opacity: 0;
  left: 250px;
}

.h6 {
  font-size: 10px;
  text-align: center;
  margin: 0px 0px 0px 0px;
}

.section {
  width: 100%;
}

.prataregular {
  font-family: "Prata", serif;
  font-weight: 400;
  font-style: normal;
}

.bebasneue {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.simpletext {
  /* text-align:center;
  margin:auto;
  color:gray;
  position:relative;
  z-index:5; */
}

/* Media query for screens less than 768px */
@media (max-width: 767px) {
  .h1 {
    font-size: 24vw;
  }

  .padding-bottom-100 {
    padding: 0px 0px 50px 0px;
  }

  .pin-spacer{
    margin-bottom: 0px!important;
  }
}